import React from 'react'
import cx from 'classnames'

import { Icon, IconProps, IconName, Text, TextBaseProps, IconColor } from 'components/dataDisplay'

import type { TextColor } from 'components/dataDisplay'


interface IconWithTextProps {
  className?: string
  message: string
  iconName: IconName
  color?: TextColor
  iconColor?: IconColor
  textColor?: TextColor
  textProps?: Partial<Omit<TextBaseProps, 'color'>> & { title?: string }
  iconRotate?: IconProps['rotate']
}

const IconWithText: React.FC<IconWithTextProps> = (props) => {
  const {
    className,
    message, iconName,
    color = 'rocky',
    textProps = {},
    iconColor,
    textColor,
    iconRotate,
  } = props

  const { title } = textProps

  return (
    <div className={cx(className, 'flex items-center')}>
      <Icon
        className="no-shrink"
        color={iconColor || color}
        name={iconName}
        rotate={iconRotate}
      />
      <Text
        className={cx('ml-6px', textProps?.className)}
        size={textProps?.size || 'c13'}
        color={textColor || color}
        message={message}
        // title={title ? title : message.length > 20 ? message : null}
      />
    </div>
  )
}

export default IconWithText